cs.ns("app.ui.composite.abstract.modal");
app.ui.composite.abstract.modal.ctrl = cs.clazz({
    extend:   app.fw.abstract.ctrl,
    dynamics: {
        acceptCallback: null,
        cancelCallback: null
    },
    protos:   {

        create (modelClazz, viewClazz) {
            this.base(modelClazz, viewClazz);

            this.registerAPI("initializeDialog", (initArgs) => {
                this.initializeDialog(initArgs);
            });
        },

        prepare () {
            this.base();

            this.observeOwnModel("event:modalIsShown", () => {
                this.handleModalIsShown();
            });

            this.observeOwnModel("event:cancelModal", () => {
                this.cancelModal();
            });

            this.observeOwnModel("event:acceptModal", () => {
                this.acceptModal();
            });
        },

        // kann bei Bedarf überschrieben werden
        initializeDialog (initArgs) {
            this.acceptCallback = initArgs.acceptCB;
            this.cancelCallback = initArgs.cancelCB;
            this.model.value("param:title", initArgs.title);
        },

        // kann bei Bedarf überschrieben werden
        handleModalIsShown () {
            this.model.value("command:focus", true);
        },

        acceptModal () {
            if (typeof this.acceptCallback === "function") {
                this.acceptCallback();
            }
        },

        // die Methode kann von der konkreten Komponente aufgerufen werden, dort können Parameter optional übergeben werden
        cancelModal (params) {
            if (typeof this.cancelCallback === "function") {
                this.cancelCallback(params);
            }
        }

    }
});
